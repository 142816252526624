<template>
  <v-app-bar app dense class="nav">
    <div class="logo">
      <img style="width:10%" class="mr-2 ekiti" src="imgs/logoh.png" alt="">
      <router-link :to="{ name: 'Home' }">
        <img class="mt-2" src="imgs/logo.png" alt="">
      </router-link>

    </div>
    <v-spacer></v-spacer>

    <div class="d-flex align-center desktop" v-if="!activeUser">
      <router-link :to="{ name: 'Login' }" class="white--text text-decoration-none">
        <v-btn small text class="mr-6 pa-3 white--text ">Login</v-btn>
      </router-link>

      <router-link :to="{ name: 'Register' }" class="white--text text-decoration-none">
        <v-btn small color="secondary" class="black--text">Register</v-btn>
      </router-link>
    </div>
    <div class="d-flex align-center desktop" v-if="activeUser">
      <router-link :to="{ path: '/dashboard' }" class="white--text text-decoration-none">
        <v-btn small class="black--text mr-2">Dashboard</v-btn>
      </router-link>

      <v-btn small @click="signOut" color="secondary" class="black--text">LogOut</v-btn>

    </div>


    <div class="mobile_nav">
      <v-btn @click="openNav" depressed fab small>
        <v-icon color="">mdi-menu</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import { logOut } from '@/Auth/index';
import { nav } from "@/main.js"
export default {
  name: "NavigationBar",
  computed: {
    ...mapState(['activeUser']),
  },

  methods: {
    signOut() {
      logOut()
      localStorage.removeItem('activeUser')
      localStorage.removeItem('user_id')
      this.$router.go({ name: "Home" })
    },

    openNav() {
      nav.$emit("navigation");

    },
  }

};
</script>

<style lang="scss" scoped>
.nav {
  background-color: transparent !important;
  margin: auto 0;


}

.logo {
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 9px;
}

.logo img {
  width: 30%;

}

.mobile_nav {
  display: none;
}

.ekiti{
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}

@media (max-width:900px) {
  .desktop {
    display: none !important;
  }

  .mobile_nav {
    display: initial;
  }

  .nav {
    background-color: transparent !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo img {
    width: 30%;

  }

}
</style>
