<template>
  <div class="homeView">
      <NavigationBar class="bar" />
    <div class="main">
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
import NavigationBar from '@/components/navs/NavigationBar.vue';
export default {
  name: "HomeView",
  components: {
    NavigationBar
  }

}
</script>

<style lang="scss" scoped>
.line-height {
  line-height: 1;
}

.main {
  overflow: hidden;
  overflow-y: scroll;
}

.main::-webkit-scrollbar {
  width: 16px;
  
}

.main::-webkit-scrollbar-track {
  background-color: green !important;      /* color of the tracking area */
}



.nav {
  height: 10vh !important;
  background-color: green !important;

}

.container {
  width: 100% !important;
}

.bar{
  z-index: 111111;
}


@media (max-width:900px) {
  .main::-webkit-scrollbar {
    display: none;
  }

}
</style>