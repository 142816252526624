<template>
  <v-app>
    <v-main class="main">
      <router-view></router-view>
    </v-main>
    <v-snackbar :color="snackcolor" v-model="snackbar" :timeout="timeout">
      {{ snackmsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-navigation-drawer class="deep-yellow drawer accent-4 draw" dark v-model="drawer" absolute temporary>
      <div v-if="activeUser">
        <v-list >
          <div v-for="item in items" :key="item.title" link>
            <v-list-item v-if="item.loggedin" exact :to="{ name: item.urlName }">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <h4>{{ item.title }}</h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

        </v-list>

      </div>
      <div v-if="!activeUser">
        <v-list>
          <div v-for="item in items" :key="item.title" link>
            <v-list-item v-if="!item.loggedin" exact :to="{ name: item.urlName }">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <h4>{{ item.title }}</h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

        </v-list>

      </div>


      <template v-slot:append>
        <div class="pa-2 auth" >
          <v-btn v-if="activeUser" @click="signOut" block color="yellow">
            <h4 style="color:black;">Log Out</h4>
          </v-btn>
          <v-btn v-if="!activeUser" :to="{ name: 'Register' }" block color="yellow">
            <h4 style="color:black;">Get Started</h4>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import { nav } from "@/main.js"; 
import { bus } from "@/main.js";
import { logOut } from '@/Auth'
import { mapState } from "vuex";
export default {

  data: () => ({
    curuser: "",
    snackbar: false,
    snackmsg: '',
    snackcolor: '',
    timeout: 2000,
    drawer: false,
    loading: false,
    items: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard', urlName: "DashboardHome", loggedin: true },
      { title: "Overview", icon: "mdi-account", urlName: "CompanyInfo", loggedin: true },
      { title: "Business Info", icon: "mdi-information-variant", urlName: "BusinessInfo", loggedin: true },
      { title: "Founder's Info", icon: "mdi-account-tie", urlName: "FounderInfo", loggedin: true },
      { title: 'Home', icon: 'mdi-home', urlName: "Home", loggedin: false },
      { title: "Login", icon: "mdi-login", urlName: "Login", loggedin: false },
    ],
  }),
  created() {
    bus.$on("snackbar", (event) => {
      this.snackcolor = event.color
      this.snackbar = true;
      this.snackmsg = event.msg

    });

    nav.$on("navigation", () => {
      this.drawer = true
    })
  },
  beforeMount() {
    let checkUser = localStorage.getItem('user_id')
    this.curuser = JSON.parse(checkUser);
    if (this.curuser != null) {
      this.$store.dispatch('ActiveUser', this.curuser)
    }

  },
  computed:{
    ...mapState(['activeUser'])
  },
  methods: {
    signOut() {
      this.loading = true
      logOut()
      localStorage.removeItem('activeUser')
      localStorage.removeItem('user_id')
      this.loading = false
      this.$router.go({ name: "main" })
    }
  }

};
</script>

<style lang="scss" scoped>
.drawer {
  height: 92.5vh !important;
  background: green;
  z-index: 1111111111;
}


</style>