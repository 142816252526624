import Vue from 'vue';

import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeUser: "",
    companyDetails: {},
    qualifications: ["PhD", "Master", "Bachelor", "OND", "HND", "NCE", "SSCE", "None", "Others"],
    sectors: [
      "Agriculture",
      "Aviation",
      "Commercial/Retail",
      "Constructuion",
      "Education and Training",
      "Energy and Power Generation",
      "FMCG",
      "Fashion",
      "FInancial Services",
      "Logistics",
      "Healthcare",
      "ICT",
      "Manufacturing",
      "Media and Entertainment",
      "Oil & Gas",
      "Professional Services",
      "Telecommunication",
      "Tourism/Hospitality",
      "Transporation",
      "Waste Management",
      "Others"
    ],
    lgas: [
      "Ado-Ekiti",
      "Efon",
      "Ekiti-East",
      "Ekiti-South-West",
      "Ekiti-West",
      "Emure",
      "Gbonyin",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun-Ifelodun",
      "Ise-Orun",
      "Moba",
      "Oye",
    ],

    
  },
  getters: {
  },
  mutations: {

    SET_ACTIVE_USER(state, payload) {
      localStorage.setItem("activeUser", JSON.stringify(payload));
      state.activeUser = payload;
    },
    SET_COMPANY_DETAILS(state, payload) {
      state.companyDetails = payload;
    },
  },
  actions: {
    ActiveUser({ commit }, payload) {
      commit("SET_ACTIVE_USER", payload);
    },
    CompanyDetails({ commit }, payload) {
      commit("SET_COMPANY_DETAILS", payload);
    },
  },
  modules: {
  }
})
